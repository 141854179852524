<template>
  <a 
    :class="['badge badge-pill alt-no-pointer px-3 py-1 mr-2 jiraStatusButton']"
    :style="`background-color:${ jiraStatus.color } !important;`"
    disabled
  >
    <font-awesome-icon
      class="mr-2"
      icon="exclamation-circle"
    />
    <span>{{ jiraStatus.status }}</span>
  </a>
</template>

<script>
export default {
  name: "JiraStatusButton",
  props: {
    jiraStatus: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayIcon () {
      if (this.jiraStatus.status === 'Maintenance') {
        return true;
      }
      if (this.jiraStatus.status === 'Open') {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>
a * {
  color: white;
  font-weight: bold;
}
.jiraStatusButton {
  font-size: 12px;
}
</style>
